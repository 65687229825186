<template>
  <FormContainer @submitForm="submit">
    <FeedbackAlert v-if="feedback === false">Errore nel salvataggio dei dati! Riprovare</FeedbackAlert>

    <div class="row">
      <FormInputTextWidget
        class="col-md-6"
        label="Nome"
        v-model="internalValue.name"
        :errorMsg="errorHash.name"
        @input="removeFieldError('name')"
      />

      <div class="col-12 mt-4 text-right">
        <button class="btn btn-primary px-5 font-weight-bold">Salva</button>
      </div>
    </div>
  </FormContainer>
</template>

<script>

import validateMixin from '@/libs/Form/mixins/validate.js';

import FormInputTextWidget from '@/libs/Form/components/Inputs/Widgets/FormInputTextWidget.vue';
import FeedbackAlert from '@/libs/Feedback/components/Alert.vue';
import FormContainer from '@/views/components/Form/FormContainer.vue';

export default {
  mixins: [validateMixin],
  components: {
    FormContainer,
    FormInputTextWidget,
    FeedbackAlert,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        name: null,
      }),
    },
    edit: {
      type: Boolean,
      default: false,
    },
    feedback: {
      type: Boolean,
      default: null,
    },
  },
  data () {
    return {
      iValue: { ...this.value },
    };
  },
  computed: {
    internalValue: {
      get () {
        return this.iValue;
      },
      set (value) {
        this.iValue = value;
      },
    },
  },
  watch: {
    value: {
      handler (newValue) {
        this.iValue = { ...newValue };
      },
      immediate: true,
    },
  },
  methods: {
    removeFieldError (field) {
      this.removeFeedback();
      this.removeError(field);
    },
    removeFeedback () {
      this.$emit('update:feedback', null);
    },
    isValid () {
      this.clearErrors();
      this.removeFeedback();

      if (!this.iValue.name) {
        this.addError('Inserisci il nome', 'name');
      }

      return !this.hasErrors;
    },
    submit () {
      if (!this.isValid()) {
        return;
      }

      const valueToReturn = { ...this.iValue };

      this.$emit('submitForm', valueToReturn);
    },
  },
};

</script>
