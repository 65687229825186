<template>
  <main>
    <PageTitleContainer>
      <PageTitle removeMargin>Modifica uvaggio {{ blend ? blend.name : '' }}</PageTitle>

      <div class="mb-3 text-right">
        <router-link class="btn btn-outline-secondary" :to="{ name: 'inventory.blends' }">Torna indietro</router-link>
      </div>
    </PageTitleContainer>

    <div class="container">
      <BlendForm v-if="blend" :value="blend" :feedback.sync="feedback" @submitForm="saveBlend" edit />
    </div>
  </main>
</template>

<script>

import $api from '@/libs/Api/services/api.js';

import PageTitle from '@/views/components/Private/PageTitle.vue';
import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import BlendForm from '@/views/components/Form/BlendForm.vue';

export default {
  components: {
    PageTitle,
    PageTitleContainer,
    BlendForm,
  },
  data () {
    return {
      blend: null,
      feedback: null,
    };
  },
  methods: {
    success () {
      this.feedback = true;
      this.$router.replace({ name: 'inventory.blends' });
    },
    failed (err) {
      this.feedback = false;
      this.$log.error(err);
    },
    saveBlend (blendForm) {
      const blend = { ...blendForm };

      this.$api.updateBlend(blend.id, blend)
        .then(() => this.success())
        .catch(err => this.failed(err))
      ;
    },
  },
  async beforeRouteEnter (to, from, next) {
    $api.fetchBlend(to.params.id)
      .then(res => {
        return next(vm => {
          vm.blend = res?.data || {};
        });
      })
      .catch(err => {
        return next(vm => vm.$log.error(err));
      })
    ;
  },
};

</script>
